<template lang="pug">
div(:class="{'with-button': addElementEndpoint}")
	.combo(:class="{open, active: search !== ''}" @click="onClick" )
		input(
			@input="onInput($event.target.value)"
			:disabled="disabled"
			:value="search"
			:class ="{ invalid: required && !value }"
		)
		span(v-if="placeholder") {{ placeholder }}
		ul
			li(v-for="(value, key) in options" :key="key" @click="onInput(value, key)") {{value}}
	i(v-if="addElementEndpoint" @click="addElement")
	span(v-if="description" class="description") {{ description }}
</template>

<script>
import BaseInput from './BaseInput'
import { ref } from 'vue'
import { useApi } from '@/modules/api'
export default {
	name: 'BaseCombo',
	components: { BaseInput },
	props: {
		value: {
			type: String,
			default: ''
		},
		search: {
			type: String,
			default: ''
		},
		placeholder: {
			type: [String, null],
			default: null
		},
		options: {
			type: Object,
			default: () => {}
		},
		disabled: {
			type: Boolean,
			default: false
		},
		addElementEndpoint: {
			type: String,
			default: null
		},
		required: {
			type: Boolean,
			default: false
		},
		description: {
			type: [String, null],
			default: null
		}
	},
	emits: ['update:search', 'update:value'],
	setup (props, { emit }) {
		const open = ref(false)

		const onClick = props.disabled ? null : () => {
			open.value = !open.value
		}

		const onInput = props.disabled ? null : (value, id = null) => {
			if (!id) open.value = true
			const prev = props.search
			emit('update:search', value, prev)
			emit('update:value', id)
		}

		const addElement = props.addElementEndpoint ? async () => {
			const { post, data } = useApi(props.addElementEndpoint)
			await post({ name: props.search })
			const { id, name } = data.value
			if (id) {
				emit('update:search', name)
				emit('update:value', id)
			}
			open.value = false
		} : null

		return {
			open,
			onClick,
			onInput,
			addElement
		}
	}
}
</script>
<style lang="scss" scoped>

.combo {
	position: relative;
	line-height: 40px;
	height: 42px;

	&::before {
		content: '';
		z-index: 1;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		pointer-events: none;
	}

	ul {
		width: 100%;
		pointer-events: none;
		background-color: $bgPrimary;
		border-radius: 0 0 $radiusPrimary $radiusPrimary;
		overflow: hidden;
		border: 1px solid $borderPrimary;
		position: absolute;
		top: calc(100% - 6px);
		left: 0;
		right: 0;
		opacity: 0;
		transform: translateY(-34px);
		transition-property: opacity, transform;
		transition: 0.3s;
		z-index: 3;
	}

	li {
		white-space: nowrap;
		height: 100%;
		padding: 0 16px;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
		user-select: none;
		color: $mainColor;

		&:first-child {
			padding-top: 6px;
		}
		&:hover{
			background-color: rgba($bgMain, 0.2);
		}
	}

	span {
		pointer-events: none;
		position: absolute;
		line-height: 40px;
		top: 0;
		left: 16px;
		z-index: 4;
		color: $mainColor;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: -4px;
			right: -4px;
			bottom: 0;
			background-color: $bgPrimary;
			border-radius: $radiusPrimaryHalf;
			z-index: -1;
			display: none;
		}
	}

	input {
		font-size: 16px;
		position: relative;
		z-index: 4;
		width: 100%;
		height: 100%;
		outline: none;
		padding: 0 16px;
		background-color: $bgPrimary;
		border: 1px solid $borderPrimary;
		border-radius: $radiusPrimary;
		&.invalid{
			border: 1px solid red;
		}
	}

	&.open {
		&::before {
			pointer-events: inherit;
		}
		ul {
			pointer-events: inherit;
			opacity: 1;
			transform: translateY(0);
		}
	}

	&.active {
		span {
			transform-origin: top left;
			transform: scale(0.7) translateY(-9px);
			line-height: 20px;

			&::before {
				display: block;
			}
		}
	}
	@include for-tablet {
		&__value {
			transition-property: border-color, box-shadow;
			transition-duration: 0.3s;

			&:hover {
				border-color: $mainColorHover;
				box-shadow: $shadowPrimary;
			}
		}

		&__item{
			transition: background-color 0.3s;
		}
	}
}
.description {
	font-size: 11.5px;
    margin-left: 5px;
}
.with-button {
	display: grid;
	grid-template-columns: 1fr 40px;
	grid-gap: 4px;

	i {
		position: relative;
		z-index: 2;
		background-color: #468eaf;
		border-radius: 8px;
		cursor: pointer;

		&::after,
		&::before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			content: '';
			width: 16px;
			height: 1px;
			background-color: white;
		}

		&::before {
			transform: translate(-50%, -50%) rotate(90deg);
		}
	}
}
</style>
