<template>
	<div class="grid mb-4">
		<div class="title">Приёмка</div>
		<form @submit.prevent class="form">
			<BaseGroup title="Основные данные" class="">
				<BaseCombo
					placeholder="Добытчик"
					:options="earnersOptions"
					@click="searchEarners(earner.name)"
					@update:search="searchEarners"
					v-model:search="earner.name"
					v-model:value="earner.id"
					required
				/>
				<BaseSelect
					:disabled="!!!earner.id"
					placeholder="Участок"
					:options="fisherRegionsOptions"
					required
					v-model:option="formData.regionId"
				/>
				<BaseSelect
					:disabled="!!!formData.regionId"
					placeholder="Рыба"
					:options="fishesOptions"
					required
					v-model:option="formData.fishId"
				/>
				<BaseSelect
					:disabled="!!!formData.fishId"
					placeholder="Сорт рыбы"
					:options="fishesSortOptions"
					required
					v-model:option="formData.fishSortId"
				/>
				<BaseInput v-model='formData.weight' placeholder='Количество, в кг.' required :decimalNumber="true"/>

			</BaseGroup>

			<div class="form--controls">
				<BaseButton @click="addReception" :disabled="formIsInvalid">Создать</BaseButton>
			</div>
		</form>
		<BaseGroup title="Последние события" class="" >
			<TheList :hover="false" :map="listOfActions" />
		</BaseGroup>
	</div>
</template>

<script>
import { onMounted, ref, watch, nextTick } from 'vue'
import BaseGroup from '@/components/ui/BaseGroup'
import BaseInput from '@/components/ui/BaseInput'
import BaseButton from '@/components/ui/BaseButton'
import BaseCombo from '@/components/ui/BaseCombo'
import BaseSelect from '@/components/ui/BaseSelect'
import TheList from '@/components/TheList'
import { useApi } from '@/modules/api'
import { useAlert } from '@/modules/composition/useAlert'

export default {
	name: 'Reception',
	components: {
		BaseGroup,
		BaseInput,
		BaseButton,
		BaseCombo,
		BaseSelect,
		TheList
	},
	setup () {
		const { errorAlert } = useAlert()
		const factoryId = ref(null)
		const listOfActions = ref({})
		const allEarnersList = ref({})
		const earnersOptions = ref({})
		const fisherRegionsOptions = ref({})
		const fishesOptions = ref({})
		const fishesSortOptions = ref({})
		const formIsInvalid = ref(true)
		const earner = ref({ id: null, name: '' })

		const formData = ref({
			boardCompany2shipId: null,
			boardCompany2workerId: null,
			regionId: null,
			fishId: null,
			fishSortId: null,
			weight: null
		})

		watch(formData, async () => {
			await nextTick()
			formIsInvalid.value = document.querySelectorAll(':invalid, .invalid').length > 0
		},
		{ deep: true }
		)

		watch(
			() => earner.value.id,
			async (value) => {
				formData.value.regionId = null
				fisherRegionsOptions.value = {}
				formData.value.fishId = null
				fishesOptions.value = {}
				formData.value.fishSortId = null
				fishesSortOptions.value = {}
				if (value) {
					formData.value.boardCompany2shipId = allEarnersList.value[value].boardCompany2ship
					formData.value.boardCompany2workerId = allEarnersList.value[value].boardCompany2workers
					await getFisherRegion()
				} else {
					formData.value.boardCompany2shipId = null
					formData.value.boardCompany2workerId = null
				}
			},
			{ deep: true }
		)
		watch(
			() => formData.value.regionId,
			async () => {
				formData.value.fishId = null
				fishesOptions.value = {}
				formData.value.fishSortId = null
				fishesSortOptions.value = {}
				if (formData.value.regionId) await getFishes()
			},
			{ deep: true }
		)
		watch(
			() => formData.value.fishId,
			async () => {
				formData.value.fishSortId = null
				fishesSortOptions.value = {}
				if (formData.value.fishId) await getFishesSort()
			},
			{ deep: true }
		)

		const searchEarners = async (searchString) => {
			earnersOptions.value = {}
			Object.values(allEarnersList.value).forEach((value) => {
				if (searchString) {
					if (value.name.toLowerCase().includes(searchString.toLowerCase())) {
						earnersOptions.value[value.id] = value.name
					}
				} else {
					earnersOptions.value[value.id] = value.name
				}
			})
		}

		const getFisherRegion = async () => {
			const { get, data, error } = useApi(`dictionary/companyFisherRegion/${earner.value.id}`)
			await get()
			if (error.value) {
				errorAlert(`Ошибка при получении списка участков! ${error.value?.message}`)
			} else {
				data.value.forEach(el => {
					fisherRegionsOptions.value[el.id] = el.name
				})
			}
		}
		const getFishes = async () => {
			const { get, data, error } = useApi(`dictionary/fish/region/${formData.value.regionId}`)
			await get()
			if (error.value) {
				errorAlert(`Ошибка при получении списка рыб! ${error.value?.message}`)
			} else {
				data.value.forEach(el => {
					fishesOptions.value[el.id] = el.name
				})
			}
		}
		const getFishesSort = async () => {
			const { get, data, error } = useApi('dictionary/fishSort')
			await get()
			if (error.value) {
				errorAlert(`Ошибка при получении списка сорта рыб! ${error.value?.message}`)
			} else {
				data.value.forEach(el => {
					fishesSortOptions.value[el.id] = el.name
				})
			}
		}
		const getEarners = async () => {
			const { get, data, error } = useApi('boardCompany/allWeb')
			await get()
			if (error.value) {
				errorAlert(`Ошибка при получении списка добытчиков! ${error.value?.message}`)
			} else {
				data.value.forEach(el => {
					allEarnersList.value[el.id] = el
				})
			}
		}
		const getAction = async () => {
			const { get, data, error } = useApi(`/factory/${factoryId.value}/actions`)
			await get()

			if (error.value) {
				errorAlert(`Ошибка при получении списка событий! ${error.value?.message}`)
			} else {
				listOfActions.value = {}
				data.value.forEach(line => {
					const { id, fishName, fishSort, receptionPointName, registerDate, rfid, status, weight, workerFIO } = line
					listOfActions.value[id] = {
						left: `${receptionPointName || ''}: ${fishName || ''}, ${fishSort || ''}${weight ? ', ' + weight + 'кг' : ''}`,
						leftSub: `${status || ''} / ${workerFIO || ''}`,
						right: registerDate || '',
						rightSub: rfid || ''
					}
				})
			}
		}
		const addReception = async () => {
			const { post } = useApi('/actionBox/receptionWeb')

			const params = { ...formData.value }

			await post(params)
			formData.value = {
				boardCompany2shipId: null,
				boardCompany2workerId: null,
				regionId: null,
				fishId: null,
				fishSortId: null,
				weight: null
			}
			earner.value = { name: '', id: null }
			await getAction()
		}

		onMounted(async () => {
			const { id } = JSON.parse(localStorage.account)
			const getWorker = useApi(`worker/${id}`)
			await getWorker.get()

			if (!getWorker.error.value) {
				const placeOfWorks = getWorker.data.value.placeOfWorks.find(work => work.type === 'factory')

				const { get, data, error } = useApi(`factory/getFactoryIdByPlaceOfWorks/${placeOfWorks.id}`)
				await get()
				if (!error.value) {
					factoryId.value = data.value.factoryId
					await getAction()
				}
			}
			await getEarners()
		})

		return {
			searchEarners,
			getFisherRegion,
			getFishes,
			getFishesSort,
			earnersOptions,
			fisherRegionsOptions,
			fishesOptions,
			fishesSortOptions,
			allEarnersList,
			formData,
			earner,
			formIsInvalid,
			addReception,
			getAction,
			getEarners,
			listOfActions
		}
	}
}
</script>

<style lang="scss" scoped>
.form {
	&--controls {
		margin-top: 20px;

		.button {
			width: 120px;

			&:first-child {
				margin-right: 10px;
			}
		}
	}
}
</style>
