<template lang="pug">
.grid
	.title События
	BaseGroup(title="Сформировать отчёт" :grid="3")
		BaseInput(
			v-for="(input, key) in inputs"
			:key="key"
			:placeholder="input.placeholder"
			type="date"
			v-model="input.value"
			required
			@blur="onChangeDate"
		)
		BaseButton(:disabled="formIsInvalid" @click="saveReport") Скачать
	BaseGroup(title="Последние события")
		TheList(:map="map" :hover="false")
		BasePagination(
			v-model:value="pagination.page"
			:max="pagination.totalPages"
			@update:value="getData"
		)
</template>

<script>
import { nextTick, onMounted, reactive, ref, watch } from 'vue'

import { useApi } from '../modules/api'
import { useInputsFromTo } from '../modules/composition/useInputsFromTo'

import TheList from '../components/TheList'
import BasePagination from '../components/ui/BasePagination'
import BaseInput from '../components/ui/BaseInput'
import BaseButton from '../components/ui/BaseButton'
import BaseGroup from '../components/ui/BaseGroup'

export default {
	name: 'TheEvents',
	components: {
		BaseGroup,
		BaseButton,
		BaseInput,
		TheList,
		BasePagination
	},
	setup () {
		const { inputs } = useInputsFromTo()
		const formIsInvalid = ref(false)

		watch(inputs, async () => {
			await nextTick()
			formIsInvalid.value = document.querySelectorAll(':invalid').length > 0
		},
		{ deep: true }
		)

		const saveReport = async () => {
			const { post, data } = useApi('/actionsXml')

			const from = inputs.from.value
			const to = inputs.to.value

			const getTimestamp = (date) => +(new Date(date))

			await post({
				from: getTimestamp(from),
				to: getTimestamp(to)
			})

			const element = document.createElement('a')
			element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data.value))
			element.setAttribute('download', `Отчёт_приёмки_${from}_${to}.xml`)
			element.style.display = 'none'
			document.body.appendChild(element)
			element.click()
			document.body.removeChild(element)
		}

		const onChangeDate = () => {
			if (inputs.from.value > inputs.to.value) {
				inputs.to.value = null
			}
		}

		const map = ref({})

		const pagination = reactive({
			page: 0,
			size: 20,
			totalPages: 0
		})

		onMounted(async () => {
			await getData()
		})

		const getData = async () => {
			const { get, data } = useApi('/getAllActionBox')

			await get(`?offset=0&page=${pagination.page}&size=${pagination.size}`)
			const { content, totalPages } = data.value
			pagination.totalPages = totalPages
			map.value = {}
			content.forEach(line => {
				const { id, fishName, fishSort, receptionPointName, registerDate, rfid, status, weight, workerFIO } = line

				map.value[id] = {
					left: `${receptionPointName || ''}: ${fishName || ''}, ${fishSort || ''}${weight ? ', ' + weight + 'кг' : ''}`,
					leftSub: `${status || ''} / ${workerFIO || ''}`,
					right: registerDate || '',
					rightSub: rfid || ''
				}
			})
		}

		return {
			inputs,
			formIsInvalid,
			saveReport,
			onChangeDate,

			map,
			pagination,
			getData
		}
	}
}
</script>
